import React, { Suspense } from 'react';
import CircularProgress from '../../../components/CircularProgress';

const getBreadcrumbs = (claimId: number, claimNumber: string) => {
  return [
     {
      title: 'Заявки',
      to: '/claim',
    },
    {
      title: claimNumber,
      to: '/claim/' + claimId,
    },
    {
      title: 'Пересоздание'
    },
  ]
};

const ClaimRecreateForm = React.lazy(() => import('../components/ClaimRecreateForm'));
function ClaimRecreateView(props: any) {
  return (
    <Suspense fallback={<CircularProgress />}>
      <ClaimRecreateForm
        {...props}
        getBreadcrumbs={getBreadcrumbs}
      />
    </Suspense>
  );
}

export default ClaimRecreateView;
