import {CancelToken} from 'axios';
import {
  ADD_CLAIM_RATE_RESULT,
  AddClaimRateResult,
  Claim,
  ClaimDetail,
  ClaimRecreateRatingStatuses,
  ClaimRecreateStatuses,
  ClaimSideAddress,
  ClaimTerminals,
  RESET_CLAIM,
  RESET_CLAIM_RATE_RESULTS,
  RESET_CLAIM_RECREATE_PROCESS,
  RESET_CURRENT_CLAIM,
  Rate,
  ResetClaim,
  ResetClaimRateResults,
  ResetClaimRecreateProcess,
  ResetCurrentClaim,
  SET_CLAIM_COUNT,
  SET_CLAIM_LIST,
  SET_CLAIM_RATE_STATUS,
  SET_CLAIM_RATE_TASK,
  SET_CLAIM_RECREATE_STATUS,
  SET_CLAIM_SIDE_ADDRESS,
  SET_CLAIM_TERMINALS,
  SET_CURRENT_CLAIM,
  SET_RECREATE_RATE_RESULT_ID,
  SetClaimCount,
  SetClaimList,
  SetClaimRateStatus,
  SetClaimRateTask,
  SetClaimRecreateStatus,
  SetClaimSideAddress,
  SetClaimTerminals,
  SetCurrentClaim,
  SetRecreateRateResultId,
} from './types';
import { ThunkAction } from '../../utils/types';
import { adaptToApi } from '../../utils/adapter';

export const setClaimList = (list: Claim[]): SetClaimList => ({
  type: SET_CLAIM_LIST,
  list,
});

export const setClaimCount = (count: number): SetClaimCount => ({
  type: SET_CLAIM_COUNT,
  count,
});

export const setCurrentClaim = (claim: ClaimDetail): SetCurrentClaim => ({
  type: SET_CURRENT_CLAIM,
  claim,
});

export const setClaimSideAddress = (
  payload: ClaimSideAddress & { side: 'sender' | 'receiver' },
): SetClaimSideAddress => ({
  type: SET_CLAIM_SIDE_ADDRESS,
  payload,
});

export const setClaimTerminals = (
  payload: ClaimTerminals,
): SetClaimTerminals => ({
  type: SET_CLAIM_TERMINALS,
  payload,
});

export const setClaimRateTask = (
  payload: string | null,
): SetClaimRateTask => ({
  type: SET_CLAIM_RATE_TASK,
  payload,
});

export const addClaimRateResult = (
  payload: {
    data: Rate[],
    singleTaskId: string,
  },
): AddClaimRateResult => ({
  type: ADD_CLAIM_RATE_RESULT,
  payload,
});

export const setClaimRateStatus = (
  payload: ClaimRecreateRatingStatuses,
): SetClaimRateStatus => ({
  type: SET_CLAIM_RATE_STATUS,
  payload,
});

export const setClaimRecreateStatus = (
  payload: ClaimRecreateStatuses,
): SetClaimRecreateStatus => ({
  type: SET_CLAIM_RECREATE_STATUS,
  payload,
});

export const setRecreateRateResultId = (
  payload: string,
): SetRecreateRateResultId => ({
  type: SET_RECREATE_RATE_RESULT_ID,
  payload
})

export const resetClaimRecreateProcess = (): ResetClaimRecreateProcess => ({
  type: RESET_CLAIM_RECREATE_PROCESS,
});

export const resetClaimRateResults = (): ResetClaimRateResults => ({
  type: RESET_CLAIM_RATE_RESULTS,
});

export const resetCurrentClaim = (): ResetCurrentClaim => ({
  type: RESET_CURRENT_CLAIM,
});

export const resetClaim = (): ResetClaim => ({
  type: RESET_CLAIM,
});

export const loadClaimList = (page = 1, filters: anyObject = {}, cancelToken: CancelToken): ThunkAction => (dispatch, getState, http) => {
  const params: anyObject = {
    page,
  };

  const {
    query, status, deliveryService,
  } = filters;

  if (query) params.query = query;
  if (status && status.length) params.status = status;
  if (deliveryService && deliveryService.length) params.delivery_service = deliveryService;
  return http.get(
    '/api/v1/shipping/claim/list/',
    params,
    { cancelToken },
  ).then(
    ({ results, count }: { results: Claim[], count: number}) => {
      dispatch(setClaimList(results));
      dispatch(setClaimCount(count));
      return Promise.resolve();
    },
  );
};

export const loadClaimDetail = (claimId: string | number): ThunkAction => (dispatch, getState, http) => http.get(
  `/api/v1/shipping/claim/${claimId}/detail/`,
).then(
  (response: ClaimDetail) => {
    dispatch(setCurrentClaim(response));
    return Promise.resolve(response);
  },
);

export const approveClaim = (claimId: string | number, data: anyObject): ThunkAction => (dispatch, getState, http) => http.put(
  `/api/v1/shipping/claim/${claimId}/approve/`,
  data,
).then(
  () => {
    dispatch(loadClaimDetail(claimId));
    return Promise.resolve();
  },
);

export const dismissClaim = (claimNumber: string | number, claimId: string | number): ThunkAction => (dispatch, getState, http) => http.put(
  `/api/v1/shipping/claim/${claimNumber}/dismiss/`,
).then(
  () => {
    dispatch(loadClaimDetail(claimId));
    return Promise.resolve();
  },
);

export const loadAddressDetailsByAI = (
  rawAddress: string,
  accountId: number,
  side: 'sender' | 'receiver',
): ThunkAction => (dispatch, getState, http) => http.post(
  `/api/v1/geo/ai/address/details/`,
  adaptToApi({ rawAddress, accountId }),
).then(
  (response: ClaimSideAddress) => {
    dispatch(setClaimSideAddress({
      ...response,
      side,
    }));
    return Promise.resolve(response);
  },
);

export const updateAddressCast = (
  addressCastId: string,
  street: string,
  house: string,
  office: string,
  claimId: string | number,
  accountId: number,
  city?: string,
  postcode?: string,
): ThunkAction => (dispatch, getState, http) => {
  let data: any = { street, house, office, accountId };
  if (postcode) {
    data.postcode = postcode;
    data.city = city;
  }
  return http.put(
    `/api/v1/contacts/address/cast/${addressCastId}/update/`,
    adaptToApi(data),
  ).then(
    () => {
      dispatch(loadClaimDetail(claimId));
      return Promise.resolve();
    },
  )
};

export const initRecreateCalculation = (data: any): ThunkAction => (dispatch, getState, http) => http.post(
  `/api/v1/rates/rate/claim/recalc/`,
  adaptToApi(data),
).then(
  (response: any) => {
    dispatch(setClaimRateTask(response.singleTaskId));
    return Promise.resolve();
  },
);

export const startClaimRecreate = (data: any): ThunkAction => (dispatch, getState, http) => http.post(
  `/api/v1/shipping/claim/recreate/`,
  adaptToApi(data),
).then(
  () => {
    return Promise.resolve();
  },
);