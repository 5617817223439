import {
  ADD_CLAIM_RATE_RESULT,
  Claim,
  claimActions,
  ClaimAddress,
  ClaimDetail,
  ClaimRecreateRatingStatuses,
  ClaimRecreateStatuses,
  ClaimTerminals,
  Rate,
  RESET_CLAIM,
  RESET_CLAIM_RATE_RESULTS,
  RESET_CLAIM_RECREATE_PROCESS,
  RESET_CURRENT_CLAIM,
  SET_CLAIM_COUNT,
  SET_CLAIM_LIST,
  SET_CLAIM_RATE_STATUS,
  SET_CLAIM_RATE_TASK,
  SET_CLAIM_RECREATE_STATUS,
  SET_CLAIM_SIDE_ADDRESS,
  SET_CLAIM_TERMINALS,
  SET_CURRENT_CLAIM,
  SET_RECREATE_RATE_RESULT_ID,
} from './types';

type claimState = {
  claimList: Claim[],
  claimCount: number,
  currentClaim: ClaimDetail | null,
  claimAddress: ClaimAddress | null,
  claimTerminals: ClaimTerminals | null,
  taskId: string | null,
  rateList: Rate[],
  ratingStatus: ClaimRecreateRatingStatuses,
  recreateStatus: ClaimRecreateStatuses,
  recreateRateResultId: string | null,
}

const initialState: claimState = {
  claimList: [],
  claimCount: 0,
  currentClaim: null,
  claimAddress: null,
  claimTerminals: null,
  taskId: null,
  rateList: [],
  ratingStatus: ClaimRecreateRatingStatuses.DEFAULT,
  recreateStatus: ClaimRecreateStatuses.DEFAULT,
  recreateRateResultId: null,
};

function addRates(currentRates: Rate[], newRates: Rate[]) {
  const curIds = currentRates.map((_: Rate) => _.rateResultId);
  return [...currentRates, ...newRates.filter((_: Rate) => !curIds.includes(_.rateResultId))];
}

export default function (state = initialState, action: claimActions): claimState {
  switch (action.type) {
    case SET_CLAIM_LIST:
      return { ...state, claimList: action.list };
    case SET_CLAIM_COUNT:
      return { ...state, claimCount: action.count };
    case SET_CURRENT_CLAIM:
      return { ...state, currentClaim: action.claim };
    case SET_CLAIM_SIDE_ADDRESS:
      return { ...state, claimAddress: { ...state.claimAddress, [action.payload.side]: action.payload } }
    case SET_CLAIM_TERMINALS:
      return { ...state, claimTerminals: action.payload}
    case SET_CLAIM_RATE_TASK:
      return { ...state, taskId: action.payload, ratingStatus: ClaimRecreateRatingStatuses.START };
    case ADD_CLAIM_RATE_RESULT:
      if (action.payload.singleTaskId === state.taskId) {
        return { ...state, rateList: addRates(state.rateList, action.payload.data) };
      }
      return state
    case SET_CLAIM_RATE_STATUS:
      return { ...state, ratingStatus: action.payload }
    case SET_CLAIM_RECREATE_STATUS:
      return { ...state, recreateStatus: action.payload }
    case SET_RECREATE_RATE_RESULT_ID:
      return { ...state, recreateRateResultId: action.payload }
    case RESET_CURRENT_CLAIM:
      return { ...state, currentClaim: initialState.currentClaim };
    case RESET_CLAIM_RECREATE_PROCESS:
      return {
        ...state,
        taskId: initialState.taskId,
        rateList: initialState.rateList,
        claimAddress: initialState.claimAddress,
        claimTerminals: initialState.claimTerminals,
        ratingStatus: ClaimRecreateRatingStatuses.DEFAULT,
        recreateStatus: ClaimRecreateStatuses.DEFAULT,
        recreateRateResultId: initialState.recreateRateResultId,
      }
    case RESET_CLAIM_RATE_RESULTS:
      return {
        ...state,
        taskId: initialState.taskId,
        rateList: initialState.rateList,
        recreateRateResultId: initialState.recreateRateResultId,
        ratingStatus: ClaimRecreateRatingStatuses.DEFAULT,
      }
    case RESET_CLAIM:
      return initialState
    default: return state;
  }
}
