import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import MainView from '../views/MainView';
import PrivateRoute from './PrivateRoute';
import WaybillListOldView from '../app/waybills/views/WaybillListOldView';
import WaybillListView from '../app/waybills/views/WaybillListView';
import WaybillDetailView from '../app/waybills/views/WaybillDetailView';
import WaybillEditView from '../app/waybills/views/WaybillEditView';
import ControlListView from '../app/waybills/views/ControlListView';
import RateListView from '../app/rate/views/RateListView';
import RateGroupListView from '../app/rate/views/RateGroupListView';
import RateDetailView from '../app/rate/views/RateDetailView';
import RateGroupDetailView from '../app/rate/views/RateGroupDetailView';
import TrackingReportView from '../app/report/views/TrackingReportView';
import RateMarketingView from '../app/rate/views/RateMarketingView';
import WaybillProcessingReport from '../app/report/components/WaybillProcessingReport';
import CreateTrackingReportView from '../app/report/views/CreateTrackingReportView';
import SafetyOfCargoView from '../app/dashboards/views/SafetyOfCargoView';
import ClaimListView from '../app/claim/views/ClaimListView';
import ClaimDetailView from '../app/claim/views/ClaimDetailView';
import OperatorKeyTableView from '../app/delivery_service/views/OperatorKeyTableView';
import ChatAnswerStatisticView from '../app/statistic/views/ChatAnswerStatisticView';
import CollisionReport from '../app/report/components/CollisionReport';
import ActiveDialogReviewDetailView from '../app/reviews/views/ActiveDialogReviewDetailView';
import CollisionReportSummaryView from '../app/dashboards/views/CollisionReportSummaryView';
import OperatorSchedulerSettingsTableView from '../app/delivery_service/views/OperatorSchedulerSettingsTableView';
import TenderRatePriorityList from '../app/tender/components/TenderRatePriorityList';
import CartMarketingTableView from '../app/rate/views/CartMarketingTableView';
import GeoPolygonDetailView from '../app/delivery_service/views/GeoPolygonDetailView';
import GeoPolygonListView from '../app/delivery_service/views/GeoPolygonListView';
import InsuranceListView from '../app/waybills/views/InsuranceListView';
import InsuranceDetailView from '../app/waybills/views/InsuranceDetailView';
import ApishipDeliveryServiceTableView from '../app/delivery_service/views/ApishipDeliveryServiceTableView';
import GlobalNotificationsListView from '../app/notifications/views/GlobalNotificationsListView';
import GlobalNotificationDetailView from '../app/notifications/views/GlobalNotificationDetailView';
import FakeServiceListView from '../app/fakeService/views/FakeServiceListView';
import FakeServiceDetailView from '../app/fakeService/views/FakeServiceDetailView';
import RateCashbackListView from '../app/rateCashback/views/RateCashbackListView';
import RateCashbackDetailView from '../app/rateCashback/views/RateCashbackDetailView';
import PhonesListView from '../app/phones/views/PhonesListView';
import TerminalListView from '../app/terminals/views/TerminalListView';
import CountryListView from '../app/countries/views/CountryListView';
import ForeignInvoiceListView from '../app/foreignInvoice/views/ForeignInvoiceListView';
import ForeignInvoiceDetailView from '../app/foreignInvoice/views/ForeignInvoiceDetailView';
import AdditionalServiceListView from '../app/additionalServices/views/AdditionalServiceListView';
import AdditionalServiceDetailView from '../app/additionalServices/views/AdditionalServiceDetailView';
import AccountView from '../app/accounts/views/AccountView';
import ClaimRecreateFormView from '../app/claim/views/ClaimRecreateFormView';

function AuthorizedRouter() {
  return (
    <MainView>
      <Switch>
        <PrivateRoute exact path="/waybills/old" component={WaybillListOldView} />
        <PrivateRoute exact path="/waybills" component={WaybillListView} />
        <PrivateRoute exact path="/control_360" component={ControlListView} />
        <PrivateRoute exact path="/waybills/:id" component={WaybillDetailView} />
        <PrivateRoute exact path="/waybills/:id/edit" component={WaybillEditView} />

        <PrivateRoute exact path="/insurance" component={InsuranceListView} />
        <PrivateRoute exact path="/insurance/:id" component={InsuranceDetailView} />

        {/* <PrivateRoute exact path="/attention" component={AttentionListView} /> */}
        {/* <PrivateRoute exact path="/attention/:id" component={WaybillDetailView} /> */}
        {/* <PrivateRoute exact path="/attention/:id/edit" component={AttentionEditView} /> */}

        <PrivateRoute exact path="/rates/groups" component={RateGroupListView} />
        <PrivateRoute exact path="/rates/groups/:groupId" component={RateGroupDetailView} />
        <PrivateRoute exact path="/rates" component={RateListView} />
        <PrivateRoute exact path="/rates/marketing" component={RateMarketingView} />
        <PrivateRoute exact path="/rates/cart/marketing" component={CartMarketingTableView} />
        <PrivateRoute exact path="/rates/:rateId" component={RateDetailView} />

        <PrivateRoute exact path="/report" component={TrackingReportView} />
        <PrivateRoute exact path="/report/create" component={CreateTrackingReportView} />
        <PrivateRoute exact path="/processing" component={WaybillProcessingReport} />

        <PrivateRoute exact path="/dashboard/:deliveryService(cse|cdek)" component={SafetyOfCargoView} />
        <PrivateRoute exact path="/claim" component={ClaimListView} />
        <PrivateRoute exact path="/claim/:claimId" component={ClaimDetailView} />
        <PrivateRoute exact path="/claim/:claimId/:startStep/recreate" component={ClaimRecreateFormView} />

        <PrivateRoute exact path="/operator/keys" component={OperatorKeyTableView} />
        <PrivateRoute exact path="/operator/scheduler" component={OperatorSchedulerSettingsTableView} />

        <PrivateRoute exact path="/operator/geo" component={GeoPolygonListView} />
        <PrivateRoute exact path="/operator/geo/create" component={GeoPolygonDetailView} create />
        <PrivateRoute exact path="/operator/geo/:geoPolygonId" component={GeoPolygonDetailView} />

        <PrivateRoute exact path="/statistic/chat" component={ChatAnswerStatisticView} />
        <PrivateRoute exact path="/collision_report/summary" component={CollisionReportSummaryView} />
        <PrivateRoute exact path="/collision_report/:deliveryService(cse|cdek)" component={CollisionReport} />

        <PrivateRoute exact path="/dialog_reviews" component={ActiveDialogReviewDetailView} />

        <PrivateRoute exact path="/tender/rates" component={TenderRatePriorityList} />

        <PrivateRoute exact path="/service/apiship" component={ApishipDeliveryServiceTableView} />

        <PrivateRoute exact path="/notifications/global" component={GlobalNotificationsListView} />
        <PrivateRoute exact path="/notifications/global/create" component={GlobalNotificationDetailView} create />
        <PrivateRoute exact path="/notifications/global/:notificationId" component={GlobalNotificationDetailView} />

        <PrivateRoute exact path="/fake_service" component={FakeServiceListView} />
        <PrivateRoute exact path="/fake_service/create" component={FakeServiceDetailView} create />
        <PrivateRoute exact path="/fake_service/:groupId" component={FakeServiceDetailView} />

        <PrivateRoute exact path="/rates_cashback" component={RateCashbackListView} />
        <PrivateRoute exact path="/rates_cashback/create" component={RateCashbackDetailView} create />
        <PrivateRoute exact path="/rates_cashback/:rateCashbackId" component={RateCashbackDetailView} />

        <PrivateRoute exact path="/additional_service" component={AdditionalServiceListView} />
        <PrivateRoute exact path="/additional_service/create" component={AdditionalServiceDetailView} create />
        <PrivateRoute exact path="/additional_service/:configurationId" component={AdditionalServiceDetailView} />

        <PrivateRoute exact path="/phones" component={PhonesListView} />

        <PrivateRoute exact path="/terminals" component={TerminalListView} />

        <PrivateRoute exact path="/countries" component={CountryListView} />

        <PrivateRoute exact path='/foreign_invoice' component={ForeignInvoiceListView} />
        <PrivateRoute exact path='/foreign_invoice/:foreignInvoiceId' component={ForeignInvoiceDetailView} />

        <PrivateRoute exact path="/accounts" component={AccountView} />

        <Redirect to="/404" />
      </Switch>
    </MainView>
  );
}

export default AuthorizedRouter;
