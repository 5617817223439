import {
  CargoCast, CargoCastAdditionalServices, ContactCast, RateCast, TerminalCast,
} from '../generic_types';
import { Waybill } from '../waybills/types';

export const SET_CLAIM_LIST = 'SET_CLAIM_LIST';
export const SET_CLAIM_COUNT = 'SET_CLAIM_COUNT';
export const SET_CURRENT_CLAIM = 'SET_CURRENT_CLAIM';
export const SET_CLAIM_SIDE_ADDRESS = 'SET_CLAIM_SIDE_ADDRESS';
export const SET_CLAIM_TERMINALS = 'SET_CLAIM_TERMINALS';
export const SET_CLAIM_RATE_TASK = 'SET_CLAIM_RATE_TASK';
export const ADD_CLAIM_RATE_RESULT = 'ADD_CLAIM_RATE_RESULT';
export const SET_CLAIM_RATE_STATUS = 'SET_CLAIM_RATE_STATUS';
export const SET_CLAIM_RECREATE_STATUS = 'SET_CLAIM_RECREATE_STATUS';
export const SET_RECREATE_RATE_RESULT_ID = 'SET_RECREATE_RATE_RESULT_ID';
export const RESET_CURRENT_CLAIM = 'RESET_CURRENT_CLAIM';
export const RESET_CLAIM_RECREATE_PROCESS = 'RESET_CLAIM_RECREATE_PROCESS';
export const RESET_CLAIM_RATE_RESULTS = 'RESET_CLAIM_RATE_RESULTS';
export const RESET_CLAIM = 'RESET_CLAIM';

export enum ClaimMethod {
  SIMPLE=1,
  TO_TERMINAL=2,
  FOREIGN=3,
  ONLINE_STORE=4
}

export enum ClaimRecreateSteps {
  DEFAULT = 1,
  ADDRESS = 2,
  TARIFF = 3,
  PICKUP_DATE = 4,
}

export enum ClaimRecreateRatingStatuses {
  DEFAULT = 1,
  START = 2,
  DONE = 3,
}

export enum ClaimRecreateStatuses {
  DEFAULT = 1,
  SUCCESS = 2,
  FAILED = 3,
}

export const ClaimState = ['new', 'accept', 'complete', 'dismiss'];
export const ClaimProblemType = ['calculation', 'creation'];

export type ClaimAccountDetail = {
  accountId: number,
  accountTitle: string,
};

export type Rate = {
  price: number;
  rateResultId: string,
  code: string,
  name: string,
  cost: number,
  maxDays: number,
  minDays: number,
}

export type Claim = {
  claimId: number,
  method: ClaimMethod,
  state: typeof ClaimState[number],
  number: string,
  accountId: number,
  createdAt: string,
  problemType: typeof ClaimProblemType[number],
  comment: string | null,

  senderContactId: string,
  receiverContactId: string,
  cargoId: string,

  rateResultId: string,
  receiverTerminalId: string,
  senderTerminalId: string,
  pickupAt: string,
  account: null | ClaimAccountDetail,
  waybill: null | Waybill,
  linkedClaimList: string[],
  deliveryService?: string,
  senderTerminal: null | TerminalCast,
  receiverTerminal: null | TerminalCast,
  estimatedProcessingDate?: string
};

export type ClaimDetail = Claim & {
  cargo: CargoCast,
  senderContact: null | ContactCast,
  receiverContact: null | ContactCast,
  rateResult: null | RateCast,
  senderHiddenPhone: string,
  receiverHiddenPhone: string,
  senderHiddenPhoneList: string[],
  receiverHiddenPhoneList: string[],
  encryptSender: boolean,
  encryptReceiver: boolean,
  pickupPeriod: string,
  additionalServices?: CargoCastAdditionalServices[],
  createdBy?: number,
  creationError?: string,
  originalRateResultId?: string,
};

export type ClaimSideAddress = {
  street?: string,
  house?: string,
  office?: string,
  terminalCode?: string,
}

export type ClaimAddress = {
  sender?: ClaimSideAddress,
  receiver?: ClaimSideAddress,
}

export type ClaimTerminals = {
  senderTerminalCode?: string,
  receiverTerminalCode?: string,
}

export interface SetClaimList {
  type: typeof SET_CLAIM_LIST,
  list: Claim[]
}

export interface SetClaimCount {
  type: typeof SET_CLAIM_COUNT,
  count: number,
}

export interface SetCurrentClaim {
  type: typeof SET_CURRENT_CLAIM,
  claim: ClaimDetail,
}

export interface SetClaimSideAddress {
  type: typeof SET_CLAIM_SIDE_ADDRESS,
  payload: ClaimSideAddress & { side: 'sender' | 'receiver' },
}

export interface SetClaimTerminals {
  type: typeof SET_CLAIM_TERMINALS,
  payload: ClaimTerminals,
}

export interface SetClaimRateTask {
  type: typeof SET_CLAIM_RATE_TASK,
  payload: string | null,
}

export interface AddClaimRateResult {
  type: typeof ADD_CLAIM_RATE_RESULT,
  payload: {
    data: Rate[],
    singleTaskId: string,
  },
}

export interface SetClaimRateStatus {
  type: typeof SET_CLAIM_RATE_STATUS,
  payload: ClaimRecreateRatingStatuses
}

export interface SetClaimRecreateStatus {
  type: typeof SET_CLAIM_RECREATE_STATUS,
  payload: ClaimRecreateStatuses,
}

export interface SetRecreateRateResultId {
  type: typeof SET_RECREATE_RATE_RESULT_ID,
  payload: string,
}

export interface ResetCurrentClaim {
  type: typeof RESET_CURRENT_CLAIM,
}

export interface ResetClaimRecreateProcess {
  type: typeof RESET_CLAIM_RECREATE_PROCESS,
}

export interface ResetClaimRateResults {
  type: typeof RESET_CLAIM_RATE_RESULTS,
}

export interface ResetClaim {
  type: typeof RESET_CLAIM,
}

export type claimActions = 
  SetClaimList |
  SetClaimCount |
  SetCurrentClaim |
  SetClaimSideAddress |
  SetClaimTerminals |
  SetClaimRateTask |
  AddClaimRateResult |
  ResetClaimRecreateProcess |
  SetClaimRateStatus |
  SetClaimRecreateStatus |
  SetRecreateRateResultId |
  ResetCurrentClaim |
  ResetClaimRateResults |
  ResetClaim;
